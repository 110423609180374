<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.goods_name')"
                :placeholder="$t('labels.goods_name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.sub_barcode')"
                :placeholder="$t('labels.sub_barcode')"
                name="sub_barcode"
                sort-name="sub_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.time')"
                :placeholder="$t('labels.time')"
                name="date_time"
                sort-name="date_time"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.set')"
                :placeholder="$t('labels.set')"
                name="cell_id"
                sort-name="cell_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.roll')"
                :placeholder="$t('labels.roll')"
                name="cell_id"
                sort-name="cell_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectEmployee
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="id_employee"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <!-- <td>{{ item.warehouse_name }}</td> -->
            <td>{{ item.company_name }}</td>
            <td>{{ item.name }}</td>
            <td>
              <SkuDownload :item="item" />
            </td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td>{{ item.sub_barcode }}</td>
            <td>{{ formatDateTime(item.date_time) }}</td>
            <td>{{ item.new_id_goods ? item.cell_id : "" }}</td>
            <td>{{ item.old_id_goods ? item.cell_id : "" }}</td>
            <td>{{ item.employee_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex pt-3">
      <v-col cols="11">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="1">
        <v-text-field
          dense
          outlined
          hide-details
          v-model.number="page"
          :label="$t('labels.page')"
          :placeholder="$t('labels.page')"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  copyText,
  debounce,
  downloadExcelFile,
  formatDateTime,
} from "@/libs/helpers";

export default {
  name: "LocationHistory",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SkuDownload: () => import("@/components/goods/SkuDownload"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    copyItem: {},
    copies: [],
    filters: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    copyText,
    downloadExcelFile,
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient
        .post("/goods/v1/location-history", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
